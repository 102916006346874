import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = styled(Img)`
  z-index: 2;
  border-radius: 10px;
`

const HeaderImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      let image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === `${imgName}.png`,
      )
      if (!image) {
        image = data.allImageSharp.edges.find(
          edge => edge.node.fluid.originalName === `lainakuvadefault.jpg`,
        )
      }
      return <Image fluid={image.node.fluid} alt={imgName} />
    }}
  />
)
export default HeaderImage
