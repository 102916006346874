import React from 'react'
import GlobalStyle from '../components/GlobalStyle'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import HeaderBar from '../components/layout/headerBar/HeaderBar'
import ResultCard from '../components/resultCard/ResultCard'
import DropdownSelector from '../components/dropdownSelector/DropdownSelector'
import Menubar from '../components/menuBar/Menubar'
import { device } from '../utils/responsive'
import ButtonContainer from '../components/buttonComponents/ButtonContainer'
import Seo from '../components/seo/Seo'
import { useLoansJson } from '../hooks/useLoansJson'
import { useMainArticles } from '../hooks/useMainArticles'
import HeaderImage from '../components/images/HeaderImage'
import { Content } from '../components/layout/content/Content'
import BackgroundImage from 'gatsby-background-image'

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.sidebarRatio};
  @media ${device.mobileL} {
    grid-template-columns: 100%;
  }
`

const MainArea = styled.div`
  background-color: ${props => props.theme.bgContainer};
  padding: 2rem;
  margin: 1rem;
  @media ${device.mobileL} {
    padding: 0.5rem;
    margin: 0.5rem;
  }
`
const LoanCompareContainer = styled.div`
  margin: 3rem;
`

const Sidebar = styled.div`
  background-color: ${props => props.theme.bgContainer};
  margin: 1rem;
`

const ImageContainer = styled.div`
  padding: 7rem;
`

const Template = ({ data }) => {
  let { markdownRemark: post } = data
  post = Object.assign({}, post, post.fields, post.frontmatter)

  const pageData = data.markdownRemark
  const articles = useMainArticles()
  const loans = useLoansJson(pageData.frontmatter.loanType, null)
  const imageData = data.file.childImageSharp.fluid
  return (
    <>
      <Seo isBlogPost={true} postData={post} postImage={post.img} />
      <GlobalStyle>
        <Menubar />
        <BackgroundImage fluid={imageData}>
          <HeaderBar
            title={pageData.frontmatter.title}
            desc={pageData.frontmatter.desc}
          >
            <LoanCompareContainer>
              <DropdownSelector />
            </LoanCompareContainer>
          </HeaderBar>
        </BackgroundImage>
        <MainArea>
          {loans.map(n => (
            <ResultCard data={n} key={n.node.id} />
          ))}
          <ButtonContainer
            data={articles}
            currentTitle={pageData.frontmatter.title}
          />
        </MainArea>
        <ContentContainer>
          <MainArea>
            <Content data={data.markdownRemark} />
          </MainArea>
          <Sidebar>
            <DropdownSelector sidebar={true} />
          </Sidebar>
        </ContentContainer>
      </GlobalStyle>
    </>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      htmlAst
      excerpt
      frontmatter {
        title
        desc
        loanType
        img
        metaDesc
        metaTitle
        type
      }
      fields {
        newPath
        newTitle
        newMetaTitle
        newMetaDesc
      }
      headings {
        value
        depth
      }
    }
    file(base: { eq: "background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Template
