import React from 'react'
import styled from 'styled-components'
import { H1 } from '../headers/Headers'
import { device } from '../../../utils/responsive'
// Voidaan valita halutaanko radient/väri vai kuva taustaksi

const Container = styled.div`
  text-align: center;
  padding: 2rem;
  @media ${device.mobileL} {
    padding: 0.5rem;
  }
`

const DescText = styled.div`
  padding: 2rem;
  opacity: 1;

  font-weight: 600;
  color: #1f1f1f;
  line-height: 2.2rem;
  font-size: 1.6rem;
  @media ${device.mobileL} {
    padding: 0.5rem;
  }
`

const ContentArea = styled.div`
  display: grid;
  background-color: #ffffffd6;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 2rem;
  text-align: center;
  @media ${device.mobileL} {
    display: block;
    padding: 0.5rem;
  }
`

const HeaderBar = props => {
  return (
    <Container>
      <ContentArea>
        <DescText>
          {' '}
          <H1>{props.title}</H1>
          {props.desc}
        </DescText>
        {props.children}
      </ContentArea>
    </Container>
  )
}

export default HeaderBar
